<div class="modal-header">
    <h2 mat-dialog-title trans>Enter Email Address</h2>
    <button type="button" (click)="close()" class="close-button" mat-icon-button tabindex="-1">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<form ngNativeValidate (ngSubmit)="confirm()" mat-dialog-content>
    <div class="input-container">
        <label for="email"></label>
        <input type="email" [(ngModel)]="emailAddress" name="email" id="email" required>
        <div class="error" *ngIf="errors['emails.0']">{{errors['emails.0']}}</div>
    </div>

    <mat-dialog-actions>
        <button (click)="close()" type="button" class="cancel-button" mat-button trans>Cancel</button>
        <button type="submit" class="submit-button" mat-raised-button color="accent" trans>Confirm</button>
    </mat-dialog-actions>
</form>