import { AppHttpClient } from '../../common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../common/core/http/app-http-client.service";
var MailboxTagsService = /** @class */ (function () {
    /**
     * MailboxTagsService Constructor.
     */
    function MailboxTagsService(httpClient) {
        this.httpClient = httpClient;
        /**
         * All available tags.
         */
        this.allTags = [];
        /**
         * Tags representing categories.
         */
        this.categoryTags = [];
        /**
         * Ticket status tags (open, closed etc)
         */
        this.statusTags = [];
    }
    /**
     * Set specified tags on MailboxTags service.
     */
    MailboxTagsService.prototype.setTags = function (tags) {
        if (!tags)
            return;
        this.allTags = tags;
        this.statusTags = tags.filter(function (tag) { return tag.type === 'status'; });
        this.categoryTags = tags.filter(function (tag) { return tag.type === 'category'; });
    };
    /**
     * Refresh mailbox tags.
     */
    MailboxTagsService.prototype.refresh = function () {
        var _this = this;
        this.httpClient.get('tags/agent-mailbox').subscribe(function (tags) {
            _this.setTags(tags);
        });
    };
    /**
     * Check if currently active tag matches given id.
     */
    MailboxTagsService.prototype.activeTagIs = function (tag) {
        if (!this.activeTag)
            return false;
        return this.activeTag.id === tag.id || this.activeTag.name === tag.name;
    };
    /**
     * Get currently active tag ID.
     */
    MailboxTagsService.prototype.getActiveTagId = function () {
        if (this.activeTag) {
            return this.activeTag.id;
        }
    };
    /**
     * Set currently active tag based on current url
     * or default to 'open' tag.
     */
    MailboxTagsService.prototype.setActiveTag = function (id) {
        this.activeTag = this.getTagByIdOrName(id);
        // if we failed to set active tag from url,
        // default to tag with name 'open'
        if (!this.activeTag) {
            this.activeTag = this.allTags.find(function (tag) { return tag.name === 'open'; });
        }
    };
    MailboxTagsService.prototype.getTagByIdOrName = function (idOrName) {
        return this.allTags.find(function (tag) {
            return tag.id === +idOrName || tag.name === idOrName;
        });
    };
    /**
     * Getter for category tags property.
     */
    MailboxTagsService.prototype.getCategoryTags = function () {
        return this.categoryTags;
    };
    /**
     * Getter for status tags property.
     */
    MailboxTagsService.prototype.getStatusTags = function (excludeMineTag) {
        if (excludeMineTag === void 0) { excludeMineTag = false; }
        if (excludeMineTag) {
            return this.statusTags.filter(function (tag) { return tag.name !== 'mine'; });
        }
        else {
            return this.statusTags;
        }
    };
    MailboxTagsService.ngInjectableDef = i0.defineInjectable({ factory: function MailboxTagsService_Factory() { return new MailboxTagsService(i0.inject(i1.AppHttpClient)); }, token: MailboxTagsService, providedIn: "root" });
    return MailboxTagsService;
}());
export { MailboxTagsService };
