import { BEDESK_CONFIG } from './bedesk-config';
import { EnvatoPurchasesPanelComponent } from './account-settings/envato-purchases-panel/envato-purchases-panel.component';
var ɵ0 = BEDESK_CONFIG, ɵ1 = { component: EnvatoPurchasesPanelComponent };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
