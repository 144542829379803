/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-preview-overlay.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./file-preview-toolbar/file-preview-toolbar.component.ngfactory";
import * as i3 from "./file-preview-toolbar/file-preview-toolbar.component";
import * as i4 from "../../../common/file-preview/preview-files.service";
import * as i5 from "../../../common/core/config/settings.service";
import * as i6 from "../../../common/file-preview/preview-container/preview-container.component.ngfactory";
import * as i7 from "../../../common/file-preview/preview-container/preview-container.component";
import * as i8 from "./file-preview-overlay.component";
import * as i9 from "../../../common/core/ui/overlay-panel/overlay-panel-ref";
import * as i10 from "../../../common/core/ui/overlay-panel/overlay-panel-data";
var styles_FilePreviewOverlayComponent = [i0.styles];
var RenderType_FilePreviewOverlayComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FilePreviewOverlayComponent, data: {} });
export { RenderType_FilePreviewOverlayComponent as RenderType_FilePreviewOverlayComponent };
export function View_FilePreviewOverlayComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { previewContainer: 0 }), i1.ɵqud(402653184, 2, { optionsButton: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 1, "file-preview-toolbar", [], null, [[null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closed" === en)) {
        var pd_0 = (_co.closeOverlay() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FilePreviewToolbarComponent_0, i2.RenderType_FilePreviewToolbarComponent)), i1.ɵdid(3, 49152, null, 0, i3.FilePreviewToolbarComponent, [i4.PreviewFilesService, i5.Settings], { showCloseButton: [0, "showCloseButton"] }, { closed: "closed" }), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(5, 0, [[1, 0]], null, 1, "preview-container", [], null, null, null, i6.View_PreviewContainerComponent_0, i6.RenderType_PreviewContainerComponent)), i1.ɵdid(6, 704512, [["previewContainer", 4]], 0, i7.PreviewContainerComponent, [i4.PreviewFilesService], { files: [0, "files"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.entries; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_FilePreviewOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "file-preview-overlay", [], null, null, null, View_FilePreviewOverlayComponent_0, RenderType_FilePreviewOverlayComponent)), i1.ɵdid(1, 4440064, null, 0, i8.FilePreviewOverlayComponent, [i1.ElementRef, i9.OverlayPanelRef, i4.PreviewFilesService, i5.Settings, i10.OVERLAY_PANEL_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FilePreviewOverlayComponentNgFactory = i1.ɵccf("file-preview-overlay", i8.FilePreviewOverlayComponent, View_FilePreviewOverlayComponent_Host_0, {}, {}, []);
export { FilePreviewOverlayComponentNgFactory as FilePreviewOverlayComponentNgFactory };
