/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-footer.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/core/translations/translate.directive";
import * as i3 from "../../../common/core/translations/translations.service";
import * as i4 from "../../../common/core/config/settings.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "../../../common/core/ui/custom-menu/custom-menu.component.ngfactory";
import * as i8 from "../../../common/core/ui/custom-menu/custom-menu.component";
import * as i9 from "../../../common/auth/current-user";
import * as i10 from "./customer-footer.component";
var styles_CustomerFooterComponent = [i0.styles];
var RenderType_CustomerFooterComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CustomerFooterComponent, data: {} });
export { RenderType_CustomerFooterComponent as RenderType_CustomerFooterComponent };
export function View_CustomerFooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "meta"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(4, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Copyright \u00A9 2018, All Rights Reserved,"])), (_l()(), i1.ɵted(-1, null, [" "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "custom-menu", [["position", "footer"]], [[2, "hidden", null]], null, null, i7.View_CustomMenuComponent_0, i7.RenderType_CustomMenuComponent)), i1.ɵdid(12, 245760, null, 0, i8.CustomMenuComponent, [i4.Settings, i9.CurrentUser, i1.ChangeDetectorRef], { position: [0, "position"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_2 = "/"; _ck(_v, 8, 0, currVal_2); var currVal_5 = "footer"; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 8).target; var currVal_1 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_3 = _co.settings.get("branding.site_name"); _ck(_v, 9, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 12).shouldHide; _ck(_v, 11, 0, currVal_4); }); }
export function View_CustomerFooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "customer-footer", [["id", "customer-footer"]], null, null, null, View_CustomerFooterComponent_0, RenderType_CustomerFooterComponent)), i1.ɵdid(1, 49152, null, 0, i10.CustomerFooterComponent, [i4.Settings], null, null)], null, null); }
var CustomerFooterComponentNgFactory = i1.ɵccf("customer-footer", i10.CustomerFooterComponent, View_CustomerFooterComponent_Host_0, {}, {}, []);
export { CustomerFooterComponentNgFactory as CustomerFooterComponentNgFactory };
