import { User } from '../../shared/models/User';
import { SocialAuthService } from '../../../common/auth/social-auth.service';
import { Toast } from '../../../common/core/ui/toast.service';
import { Settings } from '../../../common/core/config/settings.service';
var EnvatoPurchasesPanelComponent = /** @class */ (function () {
    function EnvatoPurchasesPanelComponent(social, toast, settings) {
        this.social = social;
        this.toast = toast;
        this.settings = settings;
        this.accountSettingsPanel = true;
        this.user = new User();
    }
    Object.defineProperty(EnvatoPurchasesPanelComponent.prototype, "envatoDisabled", {
        get: function () {
            return !this.settings.get('envato.enable');
        },
        enumerable: true,
        configurable: true
    });
    EnvatoPurchasesPanelComponent.prototype.updatePurchases = function () {
        var _this = this;
        this.social.connect('envato').then(function (user) {
            _this.user.social_profiles = user.social_profiles;
            _this.user.purchase_codes = user['purchase_codes'];
            _this.toast.open('Updated envato purchases.');
        });
    };
    return EnvatoPurchasesPanelComponent;
}());
export { EnvatoPurchasesPanelComponent };
